import { deepEqual } from 'fast-equals';
import { combineReducers, type Reducer } from 'redux';
import {
  array,
  boolean,
  type Infer,
  nullable,
  number,
  string,
  type,
} from 'superstruct';

import { handleResponse } from '../_core/handleResponse';
import { type Money } from '../_core/money';
import { isNotNull } from '../_core/predicates';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { Network } from '../network/types';
import type { AppState, AppThunkAction } from '../store/types';

const LSF_STRING_ACCEPT_HEADER =
  'application/json; large-significand-format=string';

const AssetDetails = type({
  assetId: string(),
  name: string(),
  decimals: number(),
  description: string(),
  issueHeight: number(),
  issueTimestamp: number(),
  issuer: string(),
  quantity: string(),
  reissuable: boolean(),
  scripted: boolean(),
  minSponsoredAssetFee: nullable(string()),
  originTransactionId: string(),
});

export type AssetDetails = Infer<typeof AssetDetails>;

export const WAVES_ASSET_DETAILS: AssetDetails = {
  assetId: 'WAVES',
  name: 'Waves',
  decimals: 8,
  description: '',
  issueHeight: 0,
  issueTimestamp: new Date('2016-04-11T21:00:00.000Z').getTime(),
  issuer: '',
  quantity: '10000000000000000',
  reissuable: false,
  scripted: false,
  minSponsoredAssetFee: '100000',
  originTransactionId: '',
};

export type AssetDetailsRecord = {
  [assetId: string]: AssetDetails | undefined;
};

type UpdateWavesNftsAction = {
  type: 'UPDATE_WAVES_NFTS';
  payload: {
    address: string;
    network: Network;
    nfts: AssetDetails[];
    requestedLimit: number;
  };
};

const assetsDetailsReducer: Reducer<
  {
    [N in Network]: {
      WAVES: AssetDetails;
      [key: string]: AssetDetails | undefined;
    };
  },
  | {
      type: 'UPDATE_WAVES_ASSETS';
      payload: {
        assetsDetails: AssetDetailsRecord;
        network: Network;
      };
    }
  | UpdateWavesNftsAction
> = (
  state = {
    [Network.Mainnet]: {
      WAVES: WAVES_ASSET_DETAILS,
    },
    [Network.Testnet]: {
      WAVES: WAVES_ASSET_DETAILS,
    },
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_WAVES_ASSETS':
      return {
        ...state,
        [action.payload.network]: {
          ...state[action.payload.network],
          ...action.payload.assetsDetails,
        },
      };
    case 'UPDATE_WAVES_NFTS':
      return {
        ...state,
        [action.payload.network]: {
          ...state[action.payload.network],
          ...Object.fromEntries(
            action.payload.nfts.map(nft => [nft.assetId, nft])
          ),
        },
      };
    default:
      return state;
  }
};

const WavesAssetBalanceResponse = type({
  assetId: string(),
  balance: string(),
  minSponsoredAssetFee: nullable(string()),
  sponsorBalance: nullable(string()),
});

export type WavesAssetBalanceResponse = Infer<typeof WavesAssetBalanceResponse>;

const WavesBalanceDetailsResponse = type({
  address: string(),
  regular: string(),
  generating: string(),
  available: string(),
  effective: string(),
});

export type WavesBalanceResponse = Infer<typeof WavesBalanceDetailsResponse>;

type BalancesState = {
  [N in Network]: {
    [address: string]:
      | {
          wavesBalance: WavesBalanceResponse;
          assetBalances: {
            [assetId: string]: WavesAssetBalanceResponse | undefined;
          };
        }
      | undefined;
  };
};

const balancesReducer: Reducer<
  BalancesState,
  {
    type: 'UPDATE_WAVES_BALANCES';
    payload: {
      address: string;
      wavesBalance: WavesBalanceResponse;
      balances: WavesAssetBalanceResponse[];
      network: Network;
    };
  }
> = (
  state = {
    [Network.Mainnet]: {},
    [Network.Testnet]: {},
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_WAVES_BALANCES': {
      const balances = Object.fromEntries(
        action.payload.balances.map(balance => [balance.assetId, balance])
      );

      balances[WAVES_ASSET_DETAILS.assetId] = {
        assetId: WAVES_ASSET_DETAILS.assetId,
        balance: action.payload.wavesBalance.available,
        minSponsoredAssetFee: WAVES_ASSET_DETAILS.minSponsoredAssetFee,
        sponsorBalance: action.payload.wavesBalance.available,
      };

      const updatedState = {
        wavesBalance: action.payload.wavesBalance,
        assetBalances: balances,
      };

      if (
        deepEqual(
          updatedState,
          state[action.payload.network][action.payload.address]
        )
      )
        return state;

      return {
        ...state,
        [action.payload.network]: {
          ...state[action.payload.network],
          [action.payload.address]: updatedState,
        },
      };
    }
    default:
      return state;
  }
};

const nftsReducer: Reducer<
  {
    [N in Network]: {
      [address: string]:
        | {
            ids: string[];
            largestRequestedLimit: number;
          }
        | undefined;
    };
  },
  UpdateWavesNftsAction
> = (
  state = {
    [Network.Mainnet]: {},
    [Network.Testnet]: {},
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_WAVES_NFTS': {
      let nfts = state[action.payload.network][action.payload.address];

      if (!nfts) {
        nfts = {
          ids: action.payload.nfts.map(nft => nft.assetId),
          largestRequestedLimit: action.payload.requestedLimit,
        };
      } else {
        if (action.payload.requestedLimit > nfts.largestRequestedLimit) {
          nfts = {
            ...nfts,
            largestRequestedLimit: action.payload.requestedLimit,
          };
        }

        const nftIds = nfts.ids;

        if (
          action.payload.nfts.length > nfts.ids.length ||
          action.payload.nfts.some(
            (nft, index) => nft.assetId !== nftIds[index]
          )
        ) {
          nfts = {
            ...nfts,
            ids: action.payload.nfts.map(nft => nft.assetId),
          };
        }
      }

      return nfts === state[action.payload.network][action.payload.address]
        ? state
        : {
            ...state,
            [action.payload.network]: {
              ...state[action.payload.network],
              [action.payload.address]: nfts,
            },
          };
    }
    default:
      return state;
  }
};

const WavesLeasingResponse = type({
  id: string(),
  originTransactionId: string(),
  sender: string(),
  recipient: string(),
  amount: string(),
  height: number(),
  status: string(),
  cancelHeight: nullable(number()),
  cancelTransactionId: nullable(string()),
});

export type WavesLeasingResponse = Infer<typeof WavesLeasingResponse>;
export type WavesLeasing = Omit<WavesLeasingResponse, 'amount'> & {
  amount: Money;
};

type UpdateWavesLeasesAction = {
  type: 'UPDATE_WAVES_LEASES';
  payload: {
    leases: WavesLeasingResponse[];
    network: Network;
    address: string;
  };
};

const leasesReducer: Reducer<
  {
    [N in Network]: {
      [address: string]: WavesLeasingResponse[];
    };
  },
  UpdateWavesLeasesAction
> = (
  state = {
    [Network.Mainnet]: {},
    [Network.Testnet]: {},
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_WAVES_LEASES': {
      return {
        ...state,
        [action.payload.network]: {
          ...state[action.payload.network],
          [action.payload.address]: action.payload.leases,
        },
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  assetsDetails: assetsDetailsReducer,
  balances: balancesReducer,
  nfts: nftsReducer,
  leases: leasesReducer,
});

export function selectWavesNftById({
  id,
  network,
}: {
  id: string;
  network: Network;
}) {
  return (state: AppState): AssetDetails | undefined => {
    return state.cache.waves.assetsDetails[network][id];
  };
}

export function fetchWavesAssetsDetailsAction({
  assetIds,
  network,
  signal,
}: {
  assetIds: Array<string | null | undefined>;
  network: Network;
  signal?: AbortSignal;
}): AppThunkAction<Promise<void>> {
  return async dispatch => {
    if (assetIds.length === 0) return;

    const assetIdsToFetch = Array.from(new Set(assetIds))
      .filter(isNotNull)
      .filter(id => id !== 'WAVES');

    const maxAssetsPerRequest = 100;
    const assetsDetails: AssetDetailsRecord = {};

    for (let i = 0; i < assetIdsToFetch.length; i += maxAssetsPerRequest) {
      const assetsDetailsBatch = await fetch(
        new URL('assets/details', WAVES_NETWORK_CONFIGS[network].nodeUrl),
        {
          method: 'POST',
          headers: {
            Accept: LSF_STRING_ACCEPT_HEADER,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ids: assetIdsToFetch.slice(i, i + maxAssetsPerRequest),
          }),
          signal,
        }
      ).then(handleResponse(array(AssetDetails)));

      assetsDetailsBatch.forEach(assetDetails => {
        assetsDetails[assetDetails.assetId] = assetDetails;
      });
    }

    dispatch({
      type: 'UPDATE_WAVES_ASSETS',
      payload: {
        assetsDetails,
        network,
      },
    });
  };
}

export function fetchWavesBalancesAction({
  address,
  network,
  signal,
}: {
  address: string;
  network: Network;
  signal?: AbortSignal;
}): AppThunkAction<Promise<void>> {
  return async dispatch => {
    const { nodeUrl } = WAVES_NETWORK_CONFIGS[network];

    const [{ balances }, wavesBalance] = await Promise.all([
      fetch(new URL(`assets/balance/${address}`, nodeUrl), {
        headers: { accept: LSF_STRING_ACCEPT_HEADER },
        signal,
      }).then(
        handleResponse(
          type({
            address: string(),
            balances: array(WavesAssetBalanceResponse),
          })
        )
      ),
      fetch(new URL(`addresses/balance/details/${address}`, nodeUrl), {
        headers: { accept: LSF_STRING_ACCEPT_HEADER },
        signal,
      }).then(handleResponse(WavesBalanceDetailsResponse)),
    ]);

    dispatch({
      type: 'UPDATE_WAVES_BALANCES',
      payload: {
        address,
        wavesBalance,
        balances,
        network,
      },
    });
  };
}

export function fetchWavesNftsAction({
  address,
  limit,
  network,
  signal,
}: {
  address: string;
  limit: number;
  network: Network;
  signal?: AbortSignal;
}): AppThunkAction<Promise<void>> {
  return async dispatch => {
    const { nodeUrl } = WAVES_NETWORK_CONFIGS[network];

    const nfts = await fetch(
      new URL(`/assets/nft/${address}/limit/${limit}`, nodeUrl),
      {
        headers: {
          accept: LSF_STRING_ACCEPT_HEADER,
        },
        signal,
      }
    ).then(handleResponse(array(AssetDetails)));

    dispatch({
      type: 'UPDATE_WAVES_NFTS',
      payload: {
        address,
        network,
        nfts,
        requestedLimit: limit,
      },
    });
  };
}

export function fetchWavesActiveLeasesAction({
  address,
  network,
  signal,
}: {
  address: string;
  network: Network;
  signal?: AbortSignal;
}): AppThunkAction<Promise<void>> {
  return async dispatch => {
    const { nodeUrl } = WAVES_NETWORK_CONFIGS[network];

    const leases = await fetch(new URL(`/leasing/active/${address}`, nodeUrl), {
      headers: {
        accept: LSF_STRING_ACCEPT_HEADER,
      },
      signal,
    }).then(handleResponse(array(WavesLeasingResponse)));

    dispatch({
      type: 'UPDATE_WAVES_LEASES',
      payload: {
        address,
        network,
        leases,
      },
    });
  };
}
